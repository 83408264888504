import React, {useEffect, useState} from 'react';
import {
    Input,
    Button,
    Table,
    Spin,
    Card,
    Space,
    Switch,
    Tabs
} from 'antd';
import * as lodash from 'lodash';
import {
    getEthBalance,
    getZksyncEraEthBalance,
    getWethBalance,
    getUsdcBalance,
    getTotalFeeCost,
    getTotalValue,
    getTransactionCount,
    getActiveWeeks,
    getActiveDays,
    getActiveMonths,
    getBridgeValue,
    getLastTxTime,
    getTxAndTransfer,
    fetchTokenList,
    fetchLeaderboard,
    fetchETHPrice,
    getZksyncLiteBalance,
    fetchNonce,
    getZksyncLiteTransactions,
    getZksyncLiteTotalFeeCost,
    getZksyncLiteActiveWeek,
    getZksyncLiteActiveDays,
    getZksyncLiteActiveMonths,
    getZksyncLiteLastTxTime,
} from './apis';
import './Dashboard.css'

const {TextArea} = Input;

const zksyncEraColumns = [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: {
            compare: (a, b) => a.ethereum - b.ethereum
        }
    },
    {
        title: "Address",
        dataIndex: "address",
        key: "address",
        render: (text, record) => 
        <a href={'/?address=' + record.address} target="_blank">
            { `${text.substring(0, 6)}...${text.substring(text.length - 4)}` }
        </a>
    },
    {
        title: "Rank",
        dataIndex: "rank",
        key: "rank",
        sorter: {
            compare: (a, b) => a.ethereum - b.ethereum
        },
        render: (text, record) => text === null ? <Spin/>: text
    },
    {
        title: "Ethereum",
        dataIndex: "ethereum",
        key: "ethereum",
        sorter: {
            compare: (a, b) => a.ethereum - b.ethereum
        },
        render: (text, record) => text === null ? <Spin/>: <a href={
                'https://etherscan.io/address/' + record.address
            }
            target="_blank">
            {text}</a>
    },
    {
        title: "Zksync Era",
        children: [
            {
                title: "ETH",
                dataIndex: "zksync",
                key: "zksync",
                sorter: {
                    compare: (a, b) => a.zksync - b.zksync
                },
                render: (text, record) => text === null ? <Spin/>: <a href={
                        'https://explorer.zksync.io/address/' + record.address
                    }
                    target="_blank">
                    {text}</a>

            }
        ]
    }, {
        title: "Active",
        children: [
            {
                title: "Days",
                dataIndex: "activeDays",
                key: "activeDays",
                sorter: {
                    compare: (a, b) => a.activeDays - b.activeDays
                },
                render: (text, record) => text === null ? <Spin/>: text
            }, {
                title: "Weeks",
                dataIndex: "activeWeeks",
                key: "activeWeeks",
                sorter: {
                    compare: (a, b) => a.activeWeeks - b.activeWeeks
                },
                render: (text, record) => text === null ? <Spin/>: text
            }, {
                title: "Months",
                dataIndex: "activeMonths",
                key: "activeMonths",
                sorter: {
                    compare: (a, b) => a.activeWeeks - b.activeWeeks
                },
                render: (text, record) => text === null ? <Spin/>: text
            }
        ]
    }, {
        title: "Gas Cost (USD)",
        dataIndex: "fee",
        key: "fee",
        sorter: {
            compare: (a, b) => a.fee - b.fee
        },
        render: (text, record) => text === null ? <Spin/>: text
    }, {
        title: "Bridged Value (USD)",
        dataIndex: "bridgeValue",
        key: "bridgeValue",
        sorter: {
            compare: (a, b) => a.txValue - b.txValue
        },
        render: (text, record) => text === null ? <Spin/>: text
    }, {
        title: "Tx Value (USD)",
        dataIndex: "txValue",
        key: "txValue",
        sorter: {
            compare: (a, b) => a.txValue - b.txValue
        },
        render: (text, record) => text === null ? <Spin/>: text
    }, {
        title: "Tx Count",
        dataIndex: "txCnt",
        key: "txCnt",
        sorter: {
            compare: (a, b) => a.txCnt - b.txCnt
        },
        render: (text, record) => text === null ? <Spin/>: text
    }, {
        title: "Last Tx",
        dataIndex: "lastTxTime",
        key: "lastTxTime",
        render: (text, record) => text === null ? <Spin/>: text
    }
];

const zksyncLiteColumns = [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: {
            compare: (a, b) => a.ethereum - b.ethereum
        }
    },
    {
        title: "Address",
        dataIndex: "address",
        key: "address",
        render: (text, record) => 
        <a href={'/?address=' + record.address} target="_blank">
            { `${text.substring(0, 6)}...${text.substring(text.length - 4)}` }
        </a>
    },
    {
        title: "Ethereum",
        dataIndex: "ethereum",
        key: "ethereum",
        sorter: {
            compare: (a, b) => a.ethereum - b.ethereum
        },
        render: (text, record) => text === null ? <Spin/>: <a href={
                'https://etherscan.io/address/' + record.address
            }
            target="_blank">
            {text}</a>
    },
    {
        title: "Zksync Lite",
        children: [
            {
                title: "ETH",
                dataIndex: "liteETH",
                key: "zksync",
                sorter: {
                    compare: (a, b) => a.zksync - b.zksync
                },
                render: (text, record) => text === null ? <Spin/>: <a href={
                        'https://zkscan.io/explorer/accounts/' + record.address
                    }
                    target="_blank">
                    {text}</a>

            }
        ]
    }, {
        title: "Active",
        children: [
            {
                title: "Days",
                dataIndex: "liteDays",
                key: "activeDays",
                sorter: {
                    compare: (a, b) => a.activeDays - b.activeDays
                },
                render: (text, record) => text === null ? <Spin/>: text
            }, {
                title: "Weeks",
                dataIndex: "liteWeeks",
                key: "activeWeeks",
                sorter: {
                    compare: (a, b) => a.activeWeeks - b.activeWeeks
                },
                render: (text, record) => text === null ? <Spin/>: text
            }, {
                title: "Months",
                dataIndex: "liteMonths",
                key: "activeMonths",
                sorter: {
                    compare: (a, b) => a.activeWeeks - b.activeWeeks
                },
                render: (text, record) => text === null ? <Spin/>: text
            }
        ]
    }, {
        title: "Gas Cost (USD)",
        dataIndex: "liteFee",
        key: "fee",
        sorter: {
            compare: (a, b) => a.fee - b.fee
        },
        render: (text, record) => text === null ? <Spin/>: text
    }, {
        title: "Tx Count",
        dataIndex: "liteTxCnt",
        key: "txCnt",
        sorter: {
            compare: (a, b) => a.txCnt - b.txCnt
        },
        render: (text, record) => text === null ? <Spin/>: text
    }, {
        title: "Last Tx",
        dataIndex: "liteLastTxTime",
        key: "lastTxTime",
        render: (text, record) => text === null ? <Spin/>: text
    }
];

const autoRefreshInterval = 1000 * 60 * 2;

const newDataEntry = (address, number) => {
    return {
        // zksync era
        key: `${number}_${address}`,
        id: number,
        address: address,
        ethereum: null,
        zksync: null,
        weth: null,
        usdc: null,
        activeDays: null,
        activeWeeks: null,
        activeMonths: null,
        fee: null,
        txValue: null,
        txCnt: null,
        lastTxTime: null,
        rank: null,
        score: null,

        // zksync lite
        liteETH: null,
        liteDays: null,
        liteWeeks: null,
        liteMonths: null,
        liteFee: null,
        liteTxCnt: null,
        liteLastTxTime: null,
    }
}

const referLink = 'https://twitter.com/intent/follow?screen_name=tiger_web3';
const skipReferLinkKey = 'skipReferLinkKey';

export default function Dashboard() {
    const [skipReferLink, setSkipReferLink] = useState(JSON.parse(localStorage.getItem(skipReferLinkKey)) || false);
    const [data, setDataSource] = React.useState([]);
    const [enableRefresh, setEnableRefresh] = React.useState(false);

    const handleAdd = () => {
        if (!skipReferLink) {
          localStorage.setItem(skipReferLinkKey, true);
          window.open(referLink, '_blank');
          setSkipReferLink(true);
        }
        let element = document.getElementById('address');
        let raw = element.value.split('\n');
        let addresses = raw.map((item) => item.trim()).filter((item) => item.length > 0);
        let data = addresses.map((item, index) => newDataEntry(item, index + 1));
        localStorage.setItem('addresses', JSON.stringify(data));
        setDataSource(data);
        updateAllDataEntry();
    }

    const handleRefresh = () => {
        console.log(`manual refresh start`);
        updateAllDataEntry();
    }

    const updateAllDataEntry = () => {
        var data = JSON.parse(localStorage.getItem('addresses'));
        var steps = 20;
        var interval = 0;
        for (var i = 0; i < data.length; i += steps) {
            var end = Math.min(i + steps, data.length);
            setTimeout(updateDataEntries, interval, data.slice(i, end));
            interval += 2000;
        }
        var addresses = data.map((item) => item.address);
        fetchLeaderboard(addresses).then((result) => {
            for(var i = 0; i < data.length; i++) {
                if (result[i] !== null) {
                    data[i].rank = result[i].rank;
                    data[i].score = result[i].score;
                }
            }
            setDataSource(data);
            localStorage.setItem('addresses', JSON.stringify(data));
        });
    }

    const updateDataEntries = (entries) => {
        console.log(`updateDataEntries ${
            entries.length
        }`)
        entries.forEach((entry) => {
            updateDataEntry(entry);
        });
    }

    useEffect(() => {
        localStorage.removeItem('addresses');
        localStorage.removeItem('ETHUSD');
        fetchETHPrice();

        const interval = setInterval(() => {
            setEnableRefresh(enableRefresh => {
                if (enableRefresh) {
                    console.log(`auto refresh start ${
                        new Date()
                    }`);
                    updateAllDataEntry();
                }
                fetchETHPrice();
                return enableRefresh;
            });
        }, autoRefreshInterval);
        return() => clearInterval(interval);
    }, []);

    const handleAutoRefresh = () => {
        setEnableRefresh(!enableRefresh);
    }

    const masterUpdateCell = (attr, id, value) => {
        var data = JSON.parse(localStorage.getItem('addresses'));
        data[id - 1][attr] = value;
        setDataSource(data);
        localStorage.setItem('addresses', JSON.stringify(data));
    }

    const updateDataEntry = (entry) => {
        getEthBalance(entry.address).then(lodash.partial(masterUpdateCell, 'ethereum', entry.id));
        getTxAndTransfer(entry.address).then((result) => {
            var transactions = result["transactions"];
            var transfers = result["transfers"];
            if (transactions === null) {
                return;
            }
            getBridgeValue(transactions, transfers).then(lodash.partial(masterUpdateCell, 'bridgeValue', entry.id));
            getActiveDays(transactions).then(lodash.partial(masterUpdateCell, 'activeDays', entry.id));
            getActiveWeeks(transactions).then(lodash.partial(masterUpdateCell, 'activeWeeks', entry.id));
            getActiveMonths(transactions).then(lodash.partial(masterUpdateCell, 'activeMonths', entry.id));
            getLastTxTime(transactions).then(lodash.partial(masterUpdateCell, 'lastTxTime', entry.id));
            getTotalFeeCost(transactions).then(lodash.partial(masterUpdateCell, 'fee', entry.id));
            getTotalValue(transfers).then(lodash.partial(masterUpdateCell, 'txValue', entry.id));
        });
        getTransactionCount(entry.address).then(lodash.partial(masterUpdateCell, 'txCnt', entry.id));
        fetchTokenList(entry.address).then((result) => {
            if (result === null && result.balances === null) {
                return;
            }
            getZksyncEraEthBalance(result.balances).then(lodash.partial(masterUpdateCell, 'zksync', entry.id));
            getWethBalance(result.balances).then(lodash.partial(masterUpdateCell, 'weth', entry.id));
            getUsdcBalance(result.balances).then(lodash.partial(masterUpdateCell, 'usdc', entry.id));
        });
        getZksyncLiteBalance(entry.address).then(lodash.partial(masterUpdateCell, 'liteETH', entry.id));
        fetchNonce(entry.address).then(lodash.partial(masterUpdateCell, 'liteTxCnt', entry.id));
        getZksyncLiteTransactions(entry.address).then((result) => {
            if (result === null) {
                return;
            }
            getZksyncLiteTotalFeeCost(result).then(lodash.partial(masterUpdateCell, 'liteFee', entry.id));
            getZksyncLiteActiveWeek(result).then(lodash.partial(masterUpdateCell, 'liteWeeks', entry.id));
            getZksyncLiteActiveDays(result).then(lodash.partial(masterUpdateCell, 'liteDays', entry.id));
            getZksyncLiteActiveMonths(result).then(lodash.partial(masterUpdateCell, 'liteMonths', entry.id));
            getZksyncLiteLastTxTime(result).then(lodash.partial(masterUpdateCell, 'liteLastTxTime', entry.id));
        });
    }

    return (
        <>
        <div style={{ minHeight: '100vh' }}>
            <Card title="Dashboard" className='dashboard-card'>
                <TextArea id='address'
                    style={
                        {
                            height: 180,
                            marginBottom: 24
                        }
                    }
                    placeholder="One wallet address per line"/>
                <Space>
                    <Button type="default"
                        onClick={handleAdd}>Add</Button>
                    <Button type="default"
                        onClick={handleRefresh}>Refresh</Button>
                    <Switch checked={enableRefresh}
                        checkedChildren="Auto Refresh On"
                        unCheckedChildren="Auto Refresh Off"
                        onChange={handleAutoRefresh}></Switch>
                </Space>
            </Card>

            <Tabs
            type="card"
            items={
                [
                    {
                        label: `Zksync Era`,
                        key: `0`,
                        children:<Table dataSource={data}
                        columns={zksyncEraColumns}
                        style={
                            {marginTop: 20, marginBottom: 20}
                        }
                        pagination={false}/>
                    },
                    {
                        label: `Zksync Lite`,
                        key: `1`,
                        children:<Table dataSource={data}
                        columns={zksyncLiteColumns}
                        style={
                            {marginTop: 20, marginBottom: 20}
                        }
                        pagination={false}/>
                    },
                ]
            }> 
            </Tabs>

        </div>
        </>
    );
}
